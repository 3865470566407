.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.outlet-bg {
  opacity: 0.5;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow: hidden; /* Prevent scrolling */
  color: white;
}

.data {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(119,246,12,1) 0%, rgba(0,232,255,1) 100%);
  height: 100vh;
  width: 100vw;
}

.magic-p {
  font-family: 'Merriweather', Georgia, serif;
}

.magic-div {
  animation-name: div-magic;
  animation-direction: normal;
  animation-duration: 3s;
  position: relative;
  border-radius: 5px;
  padding: 10px;
}

@keyframes div-magic {
  0%   { bottom: -500px;}
  25%  { top: 0px; color: yellow; }
  50%  {color: white;}
  75%  {}
  100% {}
}

.header {
  top: 0;
  height: auto;
  background-color: #023020;
}

.LeftToRightAnimation {
  animation-name: LeftToRightAnimation;
  animation: LeftToRightAnimation 3s 1;
  animation-direction: normal;
  animation-duration: 3s;
  position: relative;
  border-radius: 5px;
  padding: 10px;
  animation-timing-function: linear;
}

@keyframes LeftToRightAnimation {
  0%   { left: -500px;}
  25%  { left: 0px;}
  50%  {}
  75%  {}
  100% {}
}

.BottomToTopAnimation {
  animation-name: BottomToTopAnimation;
  animation-direction: normal;
  animation-duration: 3s;
  position: relative;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Merriweather', Georgia, serif;
  color: aliceblue;
}


.title {
  font-size: 2rem; 
  font-family: 'Merriweather', Georgia, serif;
}

.test { 
  font-family: 'Merriweather', Georgia, serif;
}

.no-border {
  border: none;
  outline: none;
}

.background-image {
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

@keyframes BottomToTopAnimation {
  0%   { bottom: -500px;}
  25%  { top: 0px;}
  50%  {}
  75%  {}
  100% {}
}


.footer {
  background-color: #023020;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 20px;
  letter-spacing: 0px;
}

.content {
  height: 100vh !important;
  background-color: #1F1F1F;
}

.content-md {
  height: 100vh !important;
  background-color: #1F1F1F;
}

main {
  overflow: scroll;
}

NavDropdown {
  color: white;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.img-container {
  width: 300px; 
  height: 400px;
  display: flex;
  justify-content: center;
}

.img-container img {
  width: 300px; 
  height: 400px;
  object-fit: contain;
}

@keyframes div-magic {
  0%   { left: -500px;}
  25%  { left: 0px;}
  50%  {}
  75%  {}
  100% {}
}



.sub-title {
  animation-name: sub-titleAnimate;
  animation-direction: normal;
  animation-duration: 2s;
  position: relative;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Merriweather', Georgia, serif;
  color: aliceblue;
}

@keyframes sub-titleAnimate {
  from {
    bottom: -500px;
  } to {
    bottom: 0px;
  }
}

.magic-divs:hover {
  transition: 1s;
  color: rgb(55, 14, 236);
  cursor: pointer;
  background-color: rgb(24, 128, 220);
  left: 20px;
}

.outlet {
  height: 100vh;
  width: 100vw;
}

.css-filler-remaining-space {
  flex-grow : 1;
}

#box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.div-center {
  text-align: center;
}

.sticky-header {
  position: sticky; 
  top: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:hover {
  cursor: pointer;
}

article {
  background-color: #F4B93A;
}

.item-size-dp {
  font-size: 15px;
}
